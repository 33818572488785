@import '~antd/dist/antd.compact.css';

body {
  font-family: "PingFang SC", "Microsoft Yahei", Arial, ;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #09d3ac;
}

#root {
  height: 100vh;
}

.ant-layout-header {
  padding   : 0 24px;
  background: #ffffff !important;
}

.time-filter .ant-calendar-picker {
  width: 100%;
}

#create .cke_contents {
  min-height: 500px !important;
}

.table-tags.ant-tag {
  margin: 0 4px 4px 0;
}

.table-btn:visited {
  color: #609 !important;
}

.ant-btn-sm {
  padding: 0 8px;
}

.btn-group {
  display: flex;
  margin : 0 8px 12px 0;
}

.ant-form-item,
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 12px;
}

.flex-justify {
  display        : flex;
  justify-content: space-between;
  align-items    : center
}

.menu-group {
  color     : #8B93A7;
  cursor    : default;
  border-top: 1px solid #e9e9e9;
}


#content img {
  max-width: calc(100% - 16px);
  margin   : 0 auto
}

#media-menu button {
  color : rgba(0, 0, 0, 0.65);
  margin: 0 8px
}

#media-menu .selected {
  color        : #1890ff;
  border-bottom: 2px solid #1890ff;
  border-radius: 0;
  transition   : all .15s ease
}

#media-menu button:hover {
  color        : #1890ff;
  border-bottom: 2px solid #1890ff;
  border-radius: 0;
  transition   : all .15s ease
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 0;
}

.tag-center {
  text-align: center;
  width     : 100%;
}

.table-text {
  margin-right: 8px;
}


.side-drawer {
  position  : absolute;
  right     : 0;
  bottom    : 0;
  width     : 100%;
  border-top: 1px solid #e9e9e9;
  padding   : 10px 16px;
  background: #fff;
  text-align: right;
}

.ant-tabs-nav .ant-tabs-tab {
  padding-right: 8px;
  padding-left : 8px;
}

.index-logo {
  display              : inline-grid;
  grid-template-columns: repeat(9, 1fr);
  width                : 1200px;
  height               : 240px;
}

.index-logo>img {
  width     : 100px;
  height    : 60px;
  margin    : 0;
  place-self: center;
}

.dynamic-delete-button {
  cursor    : pointer;
  position  : relative;
  top       : 4px;
  font-size : 24px;
  color     : #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor : not-allowed;
  opacity: 0.5;
}

.spin-center {
  text-align   : center;
  background   : rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding      : 30px 50px;
  margin       : 20px 0;
  height       : 100%;
}

.page-content-header {
  background: #fff;
  padding   : 12px 24px 16px 24px
}

.ant-form-item-control {
  flex-grow: 1;
}

.ant-form-item-label {
  min-width: 80px;
}

.quick-panel a {
  color: rgba(0, 0, 0, .65);
}

.content {
  background: #ffffff;
  padding   : 24px;
  margin    : 0px;
}

.ant-typography code {
  font-size: 90%;
}

.page-header .ant-tabs-nav {
  margin-bottom: 0 !important;
}


.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 0;
}

.range-connector {
  width           : 30px !important;
  border-left     : 0;
  border-right    : 0;
  pointer-events  : none;
  background-color: #fff !important;
}

.ant-badge-count {
  font-size    : 11px;
  line-height  : 16px;
  height       : 16px;
  border-radius: 8px;
}

.borderless-radio-button {
  border        : none;
  border-radius : 4px !important;
  padding-inline: 8px !important;
}


.borderless-radio-button:hover {
  border: none;
}

.borderless-radio-button:not(:first-child)::before {
  background-color: transparent;
}

.borderless-radio-button:first-child {
  border: none;
}

.borderless-radio-button:last-child {
  border: none;
}

.table-search {
  background: #ffffff;
  padding   : 16px;
  margin    : 0 0 24px 0;
}

.table-main {
  background: #ffffff;
  padding   : 16px;
}

.top-menu.ant-menu-horizontal {
  line-height: 64px;
}

.top-menu .ant-menu-overflow-item {
  padding-inline: 16px;
}

/* .top-menu.ant-menu-horizontal>.ant-menu-item,
.top-menu.ant-menu-horizontal>.ant-menu-submenu {
  border-top: 2px solid transparent;
}

.top-menu.ant-menu-horizontal>.ant-menu-item:hover,
.top-menu.ant-menu-horizontal>.ant-menu-submenu:hover {
  border-bottom: 2px solid transparent;
  border-top   : 2px solid #1890ff;
} */

.table-font {
  font-family: "PingFang SC", "Microsoft Yahei", Arial, sans-serif;
  color      : #000000;
}

.table-content {
  font-size  : 16px;
  line-height: 24px;
}

.table-column {
  font-size  : 16px;
  line-height: 24px;
}

.table-header {
  font-size  : 36px;
  line-height: 48px;
}

th.vip-basic {
  border-top: 10px solid #00cc26;
}

th.vip-enterprise {
  border-top: 10px solid #0067ED;
}

th.vip-elite {
  border-top: 10px solid #002982;
}

th.vip-flagship {
  border-top: 10px solid #ff9232;
}

.vip-button {
  display      : inline-block;
  font-size    : 18px;
  width        : 111px;
  height       : 40px;
  color        : #ffffff;
  border-radius: 2px;
  text-align   : center;
  line-height  : 40px;
}

a.vip-basic {
  background: #00cc26;
}

a.vip-basic:hover {
  background: #1ad13c !important;
  color     : #ffffff;
}

a.vip-enterprise {
  background: #0067ED;
}

a.vip-enterprise:hover {
  background: #3385F0;
  color     : #ffffff;
}

a.vip-elite {
  background: #002982;
}

a.vip-elite:hover {
  background: #33539b;
  color     : #ffffff;
}

a.vip-flagship {
  background: #ff9232;
}

a.vip-flagship:hover {
  background: #ffa75b;
  color     : #ffffff;
}

.vip-table-cell {
  background-color: white !important;
}

.title-divider {
  margin          : 30px auto;
  height          : 6px;
  width           : 40px;
  border-radius   : 3px;
  background-color: #ffa601;
}

.logo {
  float      : left;
  height     : 64px;
  line-height: 64px;
  width      : 100%;
  text-align : center;
}

.logo>img {
  height: 64px;
  width : auto;
}

.coupon-list {
  display              : grid;
  grid-gap             : 1rem;
  grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
}

.coupon-list>div {
  cursor    : pointer;
  display   : flex;
  background: url(http://static.yunzitui.com/static/asset/coupon/quan.png) no-repeat;
  height    : 110px;
  width     : 244px;
  color     : #fff;
}

.coupon-left {
  width  : 200px;
  padding: 16px;
}

.coupon-right {
  width        : 44px;
  padding      : 12px;
  display      : grid;
  place-content: center;
}

.coupon-right>span {
  font-size: 16px;
}

.coupon-list>.checked {
  background: url(http://static.yunzitui.com/static/asset/coupon/quan-checked.png) no-repeat;

}

.whitelist-list {
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  display              : grid;
}

.title-link {
  text-overflow: ellipsis;
  white-space  : nowrap;
  overflow     : hidden;
  max-width    : 130px;
  color        : rgba(0, 0, 0, 0.65)
}

.notice-detail p {
  color        : rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
}

.notice-list .ant-list-item {
  padding: 4px 0;
}

.nav-list {
  display              : grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows   : repeat(3, 1fr);
  height               : 100%;
  width                : 100%;
  gap                  : 8px
}

.nav-list>div {
  cursor             : pointer;
  border-radius      : 8px;
  /* border          : 1px solid #e1e1e6; */
  background-color   : white;
  display            : flex;
  justify-content    : center;
  flex-direction     : column;
  position           : relative;
  align-items        : center;
}

.ant-layout-sider-trigger {
  background: #fff;
  color     : #002140
}

.header-left .trigger {
  padding    : 0 8px;
  font-size  : 14px;
  line-height: 64px;
  cursor     : pointer;
  transition : color 0.3s;
}

.header-left .trigger:hover {
  color: #1890ff;
}

.resource-log .ant-timeline-item {
  padding-bottom: 8px;
}

.table-title {
  display        : flex;
  flex-direction : row-reverse;
  justify-content: space-between;
}

.dashboard-content {
  padding-top: 56px;
  background : #F0F2F5;
}

.dashboard-right {
  min-width: 1200px;
  width    : 100%;
  height   : 100vh;
}

.page-header {
  background-color: #FFF;
  padding         : 0 24px 0 24px;
}

.page-header-no-footer {
  padding-bottom: 8px;
}

.full-width {
  width: 100%;
}

.hidden {
  display   : none;
  visibility: hidden;
}

.f-12 {
  font-size: 12px;
}

.m-24 {
  margin: 24px
}

.grid-3 {
  display              : grid;
  grid-template-columns: repeat(3, 1fr);
}

.gap-8 {
  gap: 8px;
}

.ant-tabs-nav-list {
  margin-right: 24px;
}

/* .ant-modal-root {
  top   : 0;
  right : 0;
  bottom: 0;
  left  : 0;
} */